<template>
    <div style="background: #ffffff;">
        <div class="chat_header flx justify-between items-center">
            <div class="chat_header_left truncate w-full">
                <div class="chat_info flx items-center truncate w-full">
                    <a-badge 
                        v-if="isMobile" 
                        :count="getCounter" 
                        class="back_badge"
                        :number-style="{
                            boxShadow: '0 0 0 0',
                            backgroundColor: primaryColor
                        }">
                        <a-button 
                            type="ui"
                            ghost
                            icon="fi-rr-angle-left"
                            flaticon
                            class="mr-2"
                            @click="$router.push({ name: 'chat-contact' })" />
                    </a-badge>
                    <div 
                        v-if="!isMobile && activeChat" 
                        class="awatar_wrapper pr-2 cursor-pointer"
                        @click="openChatInfo()">
                        <a-avatar
                            v-if="activeChat.is_public"
                            :style="activeChat.color ? `backgroundColor:${activeChat.color}` : 'backgroundColor: #cccccc'"
                            :size="38">
                            {{ avatarText }}
                        </a-avatar>
                        <a-avatar
                            v-else
                            :key="activeChat.chat_uid"
                            :size="38"
                            :style="activeChat.color ? `backgroundColor:${activeChat.color}` : 'backgroundColor: #cccccc'"
                            :src="getAvatar">
                            {{ avatarText }}
                        </a-avatar>
                    </div>
                    <div 
                        class="chat_name truncate flex items-center cursor-pointer" 
                        :class="isMobile && 'justify-center w-full text-center'"
                        @click="openChatInfo()">

                        <div class="truncate" :style="isMobile && 'line-height: 18px;'">
                            <div class="name  font-bold  truncate">
                                <span>
                                    {{ name }}
                                </span>
                                <template v-if="activeChat.recipient && activeChat.recipient.is_support" >
                                    <span 
                                        class="ml-1 text-sm"
                                        v-tippy="!isMobile ? { inertia : true, duration : '[600,300]'} : { touch: false }" 
                                        content="Техподдержка">
                                        <i class="fi fi-rr-headset"></i>
                                    </span>
                                </template>
                            </div>
                            <div
                                v-if="activeChat && activeChat.is_public && !activeTyping"
                                class="user_count font-light truncate">
                                {{ chatMember }}
                            </div>
                            <template v-if="!activeTyping">
                                <div v-if="!activeChat.is_public" class="chat_status">
                                    <div v-if="isOnline">
                                        <span>В сети</span>
                                    </div>
                                    <template v-else>
                                        <template v-if="isOffline">
                                            <span v-if="fromNowDate">Был в сети {{ $moment(isOffline.date).format("Do MMMM HH:mm") }}</span>
                                            <span v-else>Был в сети {{ $moment(isOffline.date).fromNow() }}</span>
                                        </template>
                                        <div v-else-if="userLastActivity">
                                            <span v-if="fromNowDate">Был в сети {{ $moment(userLastActivity).format("Do MMMM HH:mm") }}</span>
                                            <span v-else>Был в сети {{ $moment(userLastActivity).fromNow() }}</span>
                                        </div>
                                    </template>
                                </div>
                            </template>
                            <template v-else>
                                <div  class="text-xs font-light">
                                    <span class="text-xs font-light" v-if="!activeChat.is_public">
                                        печатает
                                        <span class="print-dot font-light">  ....</span>
                                    </span>
                                    <span v-else>
                                        <span>{{ typingPublicText }}</span>
                                        <span class="print-dot">  ....</span>
                                    </span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat_header_right flx">
                <div 
                    v-if="isMobile && activeChat" 
                    class="awatar_wrapper cursor-pointer"
                    @click="openChatInfo()">
                    <a-avatar
                        v-if="activeChat.is_public"
                        :style="activeChat.color ? `backgroundColor:${activeChat.color}` : 'backgroundColor: #cccccc'"
                        :size="38">
                        {{ avatarText }}
                    </a-avatar>
                    <a-avatar
                        v-else
                        :key="activeChat.chat_uid"
                        :size="38"
                        :style="activeChat.color ? `backgroundColor:${activeChat.color}` : 'backgroundColor: #cccccc'"
                        :src="getAvatar">
                        {{ avatarText }}
                    </a-avatar>
                </div>
                <a-button v-if="!isMobile" type="primary" ghost icon="fi-rr-video-camera" flaticon :loading="meetingLoading" @click="getMeeting()">
                    Собрание
                </a-button>
                <a-button
                    v-if="!isMobile && activeChat.workgroup"
                    class="ml-2"
                    type="ui"
                    @click="openWorkgroup()">
                    {{ activeChat.workgroup.is_project ? $t("chat.project") :  $t("chat.workgroup")}}
                </a-button>
                <a-button
                    v-if="!isMobile"
                    type="ui"
                    icon="fi-rr-menu-dots-vertical"
                    ghost
                    flaticon
                    class="ml-2"
                    shape="circle"
                    @click="openChatInfo()" />
            </div>
            <ChatInfoDrawer />
        </div>
        <div v-if="isMobile && activeChat.workgroup" class="mobile_group_link" @click="openWorkgroup()">
            {{ activeChat.workgroup.is_project ? 'Открыть проект' : 'Открыть команду' }}
        </div>
        <div v-if="isMobile" class="mobile_group_link" @click="getMeeting()">
            <a-spin :spinning="meetingLoading" size="small">
                <div class="flex items-center justify-center">
                    <i class="fi fi-rr-video-camera mr-2" />
                    Собрание
                </div>
            </a-spin>
        </div>
        <PinMessages
            v-if="activeChat && activeChat.is_active && currentPin && currentPin.results.length"
            :messageSearch="replySearch"
            :currentPin="currentPin"
            :chatData="activeChat" />
    </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import ChatInfoDrawer from './ChatInfoDrawer'
import PinMessages from '../PinMessages.vue'
import { declOfNum } from '../../utils'
export default {
    name: "ChatBodyHeader",
    components: {
        ChatInfoDrawer,
        PinMessages
    },
    props: {
        replySearch: {
            type: Function,
            default: () => {}
        }
    },
    data(){
        return {
            typing: [],
            activeTyping: false,
            timeout: null,
            meetingLoading: false,
            meetingData: null
        }
    },
    computed: {
        ...mapState({
            activeChat: state => state.chat.activeChat,
            user: state => state.user.user,
            pinMessage: state => state.chat.pinMessage,
            isMobile: state => state.isMobile,
            primaryColor: state => state.config.primaryColor
        }),
        ...mapGetters({
            chatMessages: 'chat/chatMessages',
            getReplyMessage: 'chat/replyMessage',
            getOnlineMember: 'chat/getStatusUser',
            getTyping: 'chat/getTyping'
        }),
        getCounter() {
            return this.$store.getters['navigation/getMenuCounter']('chat')
        },
        avatarText() {
            if(this.activeChat) {
                if(this.activeChat.is_public) {
                    return this.activeChat.name.charAt(0).toUpperCase()
                } else {
                    const n = this.activeChat.name.split(' ')
                    return `${n[0].charAt(0).toUpperCase()}${n[1] ? n[1].charAt(0).toUpperCase() : ''}${n[2] ? n[2].charAt(0).toUpperCase() : ''}`
                }
            }
            return ''
        },
        mobileMenu() {
            if(this.isMobile)
                return () => import('./MobileMenu.vue')
            else
                return null
        },
        getAvatar(){
            if(this.activeChat) {
                if(this.activeChat.chat_author?.id !== this.user.id){
                    return  this.activeChat.chat_author.avatar?.path || null
                } else
                    return this.activeChat.recipient.avatar?.path || null
            } else
                return null
        },
        chatMember() {
            return this.activeChat.member_count + ' '
             + declOfNum(this.activeChat.member_count,
                 [this.$t('chat.participant'), this.$t('chat.participant2'), this.$t('chat.participant3')])
        },
        name() {
            return this.activeChat ? this.activeChat.name : ''
        },
        author() {
            return this.activeChat ? this.activeChat.chat_author : null
        },
        isOffline() {
            if(this.activeChat.recipient?.id)
                return this.$store.getters['user/getUserOffline'](this.activeChat.recipient.id)
            else
                return null
        },
        isOnline() {
            if(this.activeChat.recipient?.id)
                return this.$store.getters['user/getUserStatus'](this.activeChat.recipient.id)
            else
                return null
        },
        statusUser(){
            if(this.activeChat?.recipient) {
                return this.getOnlineMember(this.activeChat.recipient.id)
            }
            return false
        },
        userLastActivity() {
            if(this.activeChat?.recipient?.last_activity) {
                return this.activeChat.recipient.last_activity
            }
            return false
        },
        isAdmin() {
            if(this.author?.id === this.user.id)
                return true
            else
                return false
        },

        fromNowDate(){

            let now = this.$moment(new Date()); //todays date
            let end = this.$moment(this.statusUser.last_activity); // another date
            let duration = this.$moment.duration(now.diff(end));
            let hours = duration.asHours();

            return hours > 23 ? true : false
        },


        currentPin() {
            if(this.activeChat && this.pinMessage[this.activeChat.chat_uid])
                return this.pinMessage[this.activeChat.chat_uid]
            else
                return false
        },

        typingPublicText(){
            let text;
            if(this.typing.length === 1){
                text = `${this.typing[0]} печатает`
            } else if(this.typing.length === 2){
                text = `${this.typing[0]}, ${this.typing[1]} печатают`
            } else {
                text = `${this.typing[0]}, ${this.typing[1]} и еще ${this.typing.length - 2} печатают`
            }

            return text
        }
    },
    watch: {
        typing(){
            if(this.typing.length === 0){
                this.activeTyping = false
            }
        }
    },
    sockets: {
        chat_typing({ data }) {
            if(data.chat_uid === this.activeChat.chat_uid){
                const find = this.typing.find(el=> el === data.user)

                if(find === undefined){
                    this.typing.push(data.user)
                    this.timeout = setTimeout(() => {
                        this.typing = this.typing.filter(el => el !== data.user)

                    }, 1500);
                } else {
                    clearTimeout(this.timeout)
                    this.timeout = setTimeout(() => {
                        this.typing = this.typing.filter(el => el !== data.user)

                    }, 1500);
                }

                this.activeTyping = true
            }
        }
    },
    methods: {
        ...mapMutations({
            TOGGLE_INFO_SIDEBAR: 'chat/TOGGLE_INFO_SIDEBAR',
            TOGGLE_TASKS_SIDEBAR: 'chat/TOGGLE_TASKS_SIDEBAR',
            SET_CHAT_MEMBERS: 'chat/SET_CHAT_MEMBERS'
        }),
        async getMeeting() {
            if(!this.meetingData) {
                try {
                    this.meetingLoading = true
                    const { data } = await this.$http.get(`/chat/${this.activeChat.chat_uid}/vks/`)
                    console.log(data, 'data')
                    if(data) {
                        this.meetingData = data
                        if(this.meetingData?.url) {
                            window.open(this.meetingData.url, '_blank')
                        }
                    }
                } catch(e) {
                    console.log(e)
                } finally {
                    this.meetingLoading = false
                }
            } else{
                if(this.meetingData?.url) {
                    window.open(this.meetingData.url, '_blank')
                }
            }
        },
        openWorkgroup(){
            let query = {...this.$route.query}
            if(this.activeChat.workgroup.is_project){
                query['viewProject'] = this.activeChat.workgroup.uid
            } else {
                query['viewGroup'] = this.activeChat.workgroup.uid
            }
            this.$router.replace({query})
        },
        showDeleteModal() {
            this.$confirm({
                title: this.$t('chat.chat_delete'),
                content: this.$t('chat.chat_delete_text'),
                okText: this.$t('chat.yes'),
                okType: 'danger',
                cancelText: this.$t('chat.no'),
                onOk() {
                    // console.log('OK');
                },
                onCancel() {
                    // console.log('Cancel');
                }
            })
        },
        openChatInfo() {
            this.TOGGLE_INFO_SIDEBAR(true)
        }
    }
}
</script>
<style scoped lang="scss">
.back_badge{
    &::v-deep{
        .ant-badge-count{
            font-size: 10px;
            min-width: 17px;
            height: 17px;
            line-height: 17px;
            top: 10px;
            right: 9px;
        }
    }
}
.mobile_group_link{
    border-bottom: 1px solid var(--borderColor);
    cursor: pointer;
    padding: 5px 15px;
    text-align: center;
    font-size: 14px;
}
.print-dot {
    display:inline-block;
    font-size: 1rem;
    clip-path: inset(0 2ch 0 0);
    animation: l 2s steps(4) infinite;
}

.chat_name{
    padding: 0 8px;
    .name{
        font-size: 14px;
    }
    .chat_status{
        font-size: 12px;
        font-weight: 300;
    }
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

</style>